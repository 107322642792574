<template>
  <div>
    <v-row class="pa-4">
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-col
          cols="12"
          sm="12"
          md="9"
          class="d-flex flex-wrap flex-row align-center"
        >
          <div class="mt-2">
            <search-expand
              v-model="search"
              :load="loadingData"
              placeholder="Buscar administrativo"
            />
          </div>
          <div class="mt-2">
            <btn-filtros
              v-model="filtrar.sesion"
              name="Sesión"
              :items="items_filtro_sesion"
            />
          </div>
          <div class="mt-2">
            <btn-filtros
              v-model="filtrar.status_user"
              name="Estatus"
              :items="items_filtro_status"
            />
          </div>
          <div class="mt-2">
            <btn-filtros
              v-model="filtrar.rol"
              name="Rol"
              multiple
              :items="items_filtro_rol"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="3"
          class="d-flex"
          :class="{
            'justify-end': $vuetify.breakpoint.smAndUp,
            'justify-center': $vuetify.breakpoint.xsOnly
          }"
        >
          <v-btn
            color="icono"
            small
            outlined
            class="ml-2 py-0 mt-4"
            :class="{ 'px-2': $vuetify.breakpoint.smAndDown }"
            @click="updateData"
          >
            <v-icon
              size="22"
              :left="$vuetify.breakpoint.mdAndUp"
              v-text="'mdi-update'"
            />
            <span v-if="$vuetify.breakpoint.mdAndUp">Actualizar</span>
          </v-btn>
        </v-col>
      </template>
      <template v-if="$vuetify.breakpoint.smAndDown">
        <v-col
          cols="12"
          sm="12"
          md="9"
          class="d-flex flex-wrap flex-row align-center"
        >
          <search-expand
            v-model="search"
            :load="loadingData"
            placeholder="Buscar administrativo"
          />
        </v-col>
        <v-col cols="10" sm="10" class="d-flex flex-wrap align-center">
          <btn-filtros
            v-model="filtrar.sesion"
            name="Sesión"
            class="mb-2 ml-2"
            :items="items_filtro_sesion"
          />
          <btn-filtros
            v-model="filtrar.status_user"
            name="Estatus"
            :items="items_filtro_status"
          />
          <btn-filtros
            v-model="filtrar.rol"
            name="Rol"
            multiple
            :items="items_filtro_rol"
          />
        </v-col>
        <v-col cols="2" class="d-flex justify-end">
          <v-btn
            color="icono"
            small
            outlined
            class="ml-2 py-0"
            :class="{ 'px-2': $vuetify.breakpoint.smAndDown }"
            @click="updateData"
          >
            <v-icon
              size="22"
              :left="$vuetify.breakpoint.mdAndUp"
              v-text="'mdi-update'"
            />
            <span v-if="$vuetify.breakpoint.mdAndUp">Actualizar</span>
          </v-btn>
        </v-col>
      </template>
    </v-row>
    <!-- <v-row>
    <v-col cols="2" v-for="user in itemsUsers">
      <span>{{user.status_user}}</span>
    </v-col>
  </v-row> -->
    <v-divider />
    <v-row class="pa-0 ma-0">
      <v-col cols="12" class="pa-0">
        <v-data-iterator
          :items="itemsUsers"
          item-key="name"
          :items-per-page="10"
          class="mx-3"
          :search="search"
          :loading="loadingData"
          v-if="$vuetify.breakpoint.mobile"
        >
          <template v-slot:default="{ items }">
            <template v-for="(user, i) in items">
              <list-mobile type-user="ven" :user="user" :key="'mobile-' + i">
                <template #action>
                  <div class="text-right">
                    <v-menu
                      bottom
                      left
                      min-width="200"
                      offset-y
                      origin="top right"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          plain
                          small
                          color="icono"
                          class="px-0"
                        >
                          <v-icon size="20" color="icono"
                            >mdi-dots-vertical</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-list class="py-0">
                        <v-list-item-group color="primary">
                          <v-list-item class="py-2" @click="gestionItem(user)">
                            <v-list-item-icon
                              class="my-2 ml-1 mr-3 align-self-center"
                            >
                              <v-icon color="light-blue darken-3" size="22">
                                {{
                                  user.user
                                    ? 'mdi-account-cog-outline'
                                    : 'mdi-account-plus'
                                }}
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              <span>{{
                                user.user
                                  ? 'Gestionar Usuario'
                                  : 'Crear Usuario'
                              }}</span>
                            </v-list-item-title>
                          </v-list-item>
                          <v-divider />
                          <v-list-item
                            class="py-2"
                            @click="closeSesion(user.user)"
                            :disabled="!user.sesion_activa"
                          >
                            <v-list-item-icon
                              class="my-2 ml-1 mr-3 align-self-center"
                            >
                              <v-icon size="22" color="light-blue darken-3"
                                >mdi-lock</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-title>Cerrar Sesión</v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </div>
                </template>
              </list-mobile>
            </template>
          </template>
          <template #loading>
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-two-line, list-item, list-item-two-line"
            ></v-skeleton-loader>
          </template>
        </v-data-iterator>
        <v-data-table
          :headers="headers"
          :items="itemsUsers"
          :search="search"
          :loading="loadingData"
          disable-sort
          class="user-table"
          v-else
        >
          <template v-slot:item.ven_des="{ item }">
            <!-- <div class="border-user"></div> -->
            <v-list-item class="px-0">
              <v-list-item-avatar class="mx-1">
                <v-icon color="primary" left class="mt-n1">
                  mdi-account-circle-outline
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="primary--text font-weight-bold"
                    >{{ item.ven_des }} - ({{ item.co_ven }})
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.user">
                  <v-chip
                    label
                    class="font-weight-medium mt-1"
                    :color="item.sesion_activa ? 'icono' : 'red'"
                    x-small
                    dark
                    v-text="
                      item.sesion_activa ? 'Sesion Activa' : 'Sesion Cerrada'
                    "
                  />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item.email="{ item }">
            <v-list-item class="px-0">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon v-if="item.user" small color="success" left
                    >mdi-shield-check</v-icon
                  >
                  <span
                    class="blue-grey--text font-weight-medium"
                    v-text="item.user ? item.user.email : ''"
                  />
                </v-list-item-title>
                <v-list-item-subtitle class="mt-1" v-if="item.user">
                  <v-chip
                    label
                    color="grey lighten-2"
                    small
                    class="mr-2"
                    v-for="(rol, i) in item.user.roles"
                    :key="'rol-' + i"
                  >
                    <span class="font-weight-medium"> {{ rol.name }} </span>
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <!-- <template v-slot:item.roles="{ item }">
          <template v-if="item.user && item.user.roles.length > 0">
            <div class="mr-2" v-for="(rol,i) in item.user.roles" :key="'rol-'+i">
             <v-icon
                color="blue darkten-2"
              >
                mdi-circle-medium
              </v-icon>
             <span class="blue-grey--text"> {{rol.name}} </span>
            </div>
          </template>
        </template> -->
          <template v-slot:item.estatus="{ item }">
            <div class="text-center">
              <v-chip
                class="ma-2 font-weight-bold"
                :color="item.user.status ? 'success-alert' : 'disabled-alert'"
                small
                label
                pill
                dark
                v-if="item.user"
              >
                {{ item.user.status ? 'Activo' : 'Inactivo' }}
              </v-chip>
              <v-chip
                label
                outlined
                class="my-2 mx-5 font-weight-bold"
                color="red"
                small
                v-else
              >
                S/U
              </v-chip>
            </div>
          </template>
          <template v-slot:item.ultimo_uso="{ item }">
            <v-icon
              v-if="item.ultimo_uso"
              small
              color="blue-grey"
              left
              class="mt-n1"
              >mdi-alarm</v-icon
            >
            <span class="blue-grey--text">{{
              item.ultimo_uso | FullDate
            }}</span>
          </template>

          <template v-slot:item.portafolios="{ item }">
            <v-chip
              label
              class="mr-2"
              color="orange"
              x-small
              dark
              v-for="(item, i) in item.portafolios"
            >
              <span class="font-weight-medium"> {{ item }} </span>
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="text-right">
              <v-menu
                bottom
                left
                min-width="200"
                offset-y
                origin="top right"
                transition="scale-transition"
              >
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    plain
                    small
                    color="icono"
                    class="px-0"
                  >
                    <v-icon size="20" color="icono">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list class="py-0">
                  <v-list-item-group color="primary">
                    <template
                      v-if="
                        (item.user &&
                          $hasPermission('configuracion.usuarios.editar')) ||
                        (item.user === null &&
                          $hasPermission('configuracion.usuarios.crear'))
                      "
                    >
                      <v-list-item class="py-2" @click="gestionItem(item)">
                        <v-list-item-icon
                          class="my-2 ml-1 mr-3 align-self-center"
                        >
                          <v-icon color="light-blue darken-3" size="22">
                            {{
                              item.user
                                ? 'mdi-account-cog-outline'
                                : 'mdi-account-plus'
                            }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          <span>{{
                            item.user ? 'Gestionar Usuario' : 'Crear Usuario'
                          }}</span>
                        </v-list-item-title>
                      </v-list-item>
                      <v-divider />
                    </template>
                    <v-list-item
                      class="py-2"
                      @click="closeSesion(item.user)"
                      :disabled="!item.sesion_activa"
                    >
                      <v-list-item-icon
                        class="my-2 ml-1 mr-3 align-self-center"
                      >
                        <v-icon size="22" color="light-blue darken-3"
                          >mdi-lock</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-title>Cerrar Sesión</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  depressed
                  class="mx-2 px-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="gestionItem(item)"
                >
                <v-icon :color="item.user ? 'blue-grey' : 'light-blue darken-3'" size="20">{{ item.user ? 'mdi-account-cog-outline' : 'mdi-account-plus' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.user ? 'Gestionar Usuario' : 'Crear Usuario' }}</span>
            </v-tooltip> -->
            </div>
          </template>
          <template #loading>
            <v-skeleton-loader
              v-bind="attrs"
              type="table-tbody"
            ></v-skeleton-loader>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <modal-account
      v-model="edit"
      :data="user"
      :loading="loading_user"
      :roles="roles"
      :portafolios="portafolios"
    />
  </div>
</template>
<script>
export default {
  name: 'DataAccounts',
  components: {
    ModalAccount: () =>
      import(
        /* webpackChunkName: "modal-account" */
        './ModalAccount.vue'
      ),
    ListMobile: () =>
      import(
        /* webpackChunkName: "modal-account" */
        './ListMobile.vue'
      ),
    BtnFiltros: () =>
      import(
        /* webpackChunkName: "btn-filtros" */
        '@/widgets/BtnFiltros.vue'
      )
  },
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    loadingData: Boolean,
    roles: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    attrs: {
      boilerplate: false,
      loading: true
    },
    edit: false,
    user: {},
    loading_user: false,
    search: '',
    items_filtro_sesion: [
      { title: 'Abierta', value: 'abierta' },
      { title: 'Cerrada', value: 'cerrada' }
    ],
    items_filtro_status: [
      { title: 'Usuario Activo', value: 'activado' },
      { title: 'Usuario Inactivo', value: 'inactivo' }
    ],
    filtrar: {
      sesion: '',
      status_user: '',
      rol: []
    },
    portafolios: [],
    reload_portafolio: true
  }),
  methods: {
    async getRoles() {
      try {
        const { data } = await this.$api.get('users/roles')
        this.roles = data.data
      } catch (error) {
        console.log(error)
      }
    },
    async gestionItem(item) {
      // this.user = item;
      this.edit = true
      this.loading_user = true
      try {
        const { data } = await this.$api.get(`users/${item.co_ven}/show`)
        this.user = data.data
      } catch (error) {
        this.$root.$showAlert(
          'Lo sentimos, hubo un error al intentar obtener los datos del usuario.',
          'error'
        )
      }
      this.loading_user = false
    },
    updateData() {
      this.$emit('procesado')
    },
    async closeSesion(user) {
      const confirmar = await this.$root.$confirm(
        '¿Estas Seguro?',
        'Desea cerra la Sesión del usuario ' + user.name,
        {
          type: 'confirm',
          btnConfirmText: 'Si, Cerrar',
          btnCancelText: 'Descartar'
        }
      )
      if (confirmar) {
        this.$emit('closeUser', user.id)
      }
    },
    async getPortafolios() {
      try {
        const { data } = await this.$api.get('portafolios')
        this.portafolios = data.data

        console.log(this.portafolios)

        this.reload_portafolio = false
      } catch (error) {
        this.reload_portafolio = true
      }
    }
  },
  computed: {
    itemsUsers() {
      let dataUsers = []
      dataUsers = this.data.map((item) => {
        return {
          ...item,
          status_user:
            item?.user && item.user.status === 0
              ? 'inactivo'
              : item?.user && item.user.status === 1
                ? 'activado'
                : '',
          sesion: item.sesion_activa === true ? 'abierta' : 'cerrada',
          rol: item.user ? item.user.roles.map((rol) => rol.name) : ['S/R'],
          portafolios: item.user
            ? item.user.portafolios
              ? item.user.portafolios
              : ['S/P']
            : ['S/P']
        }
      })

      const CustomFiltersKeys = Object.keys(this.filtrar)
      return dataUsers.filter((item) => {
        return CustomFiltersKeys.every((keyFiltro) => {
          if (this.filtrar[keyFiltro].length === 0) {
            return true
          }
          if (
            typeof this.filtrar[keyFiltro] === 'object' &&
            typeof item[keyFiltro] === 'object'
          ) {
            return this.filtrar[keyFiltro].some((element) =>
              item[keyFiltro].includes(element)
            )
          }

          return typeof this.filtrar[keyFiltro] === 'object'
            ? this.filtrar[keyFiltro].includes(item[keyFiltro].toString())
            : this.filtrar[keyFiltro]
                .toString()
                .toLowerCase()
                .includes(item[keyFiltro].toString().toLowerCase())
        })
      })
    },
    items_filtro_rol() {
      return this.roles.length > 0
        ? this.roles.map((rol) => {
            return {
              title: rol.name,
              value: rol.name
            }
          })
        : []
    }
  },
  created() {
    this.getPortafolios()
  }
}
</script>
<style lang="sass" scoped>
.border-user
  width: 0
  height: 10px
  border-left: 0px solid transparent
  border-right: 16px solid transparent
  border-top: 15px solid #43b02a
  position: absolute
  margin-left: -16px
  opacity: .7
</style>
